const languageEnum = {
	en: 5,
	cn: 14,
	au: 1,
	sg: 2,
	"de-en": 3,
	uk: 4,
	it: 7,
	mx: 8,
	es: 9,
	jp: 10,
	ru: 11,
	fr: 12,
	de: 13,
	tw: 15,
	hk: 16,
	mo: 17
};
const zendeskLocaleMap = {
	en: "en-US",
	au: "en-au",
	sg: "en-sg",
	"de-en": "en-DE",
	uk: "en-gb",
	it: "it",
	mx: "es-mx",
	es: "es",
	jp: "ja",
	ru: "en",
	fr: "fr",
	de: "de"
};

declare let zE: any;
export default defineNuxtPlugin(nuxtApp => {
	const runtimeConfig = useRuntimeConfig();
	// const cookieOptions = useCookieOptions();

	const websiteStore = useWebsiteStore();
	const gaStore = useGaStore();
	const token = useCookies("token_new");
	const userStore = useUserStore();
	const router = useRouter();

	const userInfo = computed(() => userStore.userInfo);
	const { website, iso_code, country_name, countries_id } = websiteStore;
	const { pageGroup } = storeToRefs(gaStore);
	const lcSdkUrl = ["PROD", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV)
		? "https://livechat-front.fs.com/sdk.js"
		: "https://test-livechat.whgxwl.com/sdk.js";

	let clientUserInfo = "";
	watch(
		userInfo,
		newValue => {
			console.log("userInfo", newValue);
			if (token.value && newValue) {
				const obj = {
					email: newValue?.customers_email_address || "",
					name: (newValue?.customers_firstname || "") + " " + (newValue?.customers_lastname || ""),
					mobile: newValue?.customers_telephone || ""
				};
				clientUserInfo = JSON.stringify(obj);
			}
			if (newValue && window?.zE) {
				console.log("zendesk get customer_email_address:", newValue?.customers_email_address);
				zE("messenger:set", "conversationFields", [
					{ id: "28194227804441", value: newValue?.customers_email_address || "" }
				]);
				// customer number field
				console.log("zendesk get customers_number_new:", newValue?.customers_number_new);
				zE("messenger:set", "conversationFields", [
					{ id: "35684258978073", value: newValue?.customers_number_new || "" }
				]);
			}
		},
		{ immediate: true }
	);

	function handleChatGa(event: MessageEvent) {
		const postMsgData = event.data;
		if (postMsgData?.type === "dataLayer") {
			const params = postMsgData.data;
			const gaParams = {
				...params,
				eventCategory: pageGroup.value,
				event: "uaEvent",
				eventAction: "Live Chat",
				nonInteraction: false
			};
			console.log("------ga-------", gaParams);
			window.dataLayer && window.dataLayer.push(gaParams);
		}
	}

	window.addEventListener("message", handleChatGa);

	router.afterEach((to, from) => {
		if (document.querySelector("iframe.fsLiveChat")) {
			const iframeEle = document.querySelector("iframe.fsLiveChat");
			((iframeEle as HTMLIFrameElement).contentWindow as Window).postMessage(
				{
					type: "urlChange",
					origin: window.location.href
				},
				"*"
			);
		}
	});
	console.log("webSite", website);

	// 全站切回自研livechat
	(function (w, d) {
		const lc = d.createElement("script");
		lc.type = "text/javascript";
		lc.defer = true;
		lc.src = lcSdkUrl;
		const s = d.getElementsByTagName("script")[0];
		(s.parentNode as ParentNode).insertBefore(lc, s);
		lc.onload = function () {
			if (w.fsLiveChatMount) {
				w.fsLiveChatMount({
					appId: 1, // 商城
					isoCode: iso_code, // 地区isoCode
					language: languageEnum[website as WebSiteType] || "", // 语言id
					webSite: website, // 站点
					clientUserInfo, // 登录用户信息
					fromPage: window.location.href // 可选
				});
			}
		};
	})(window, document);
	// 新增it站点测试自研livechat
	// if (!["cn", "tw", "hk", "mo", "it"].includes(website)) {
	// 	(function (w, d) {
	// 		const lc = d.createElement("script");
	// 		lc.type = "text/javascript";
	// 		lc.defer = true;
	// 		lc.id = "ze-snippet";
	// 		lc.src = "https://static.zdassets.com/ekr/snippet.js?key=a35bc69f-c6f6-4cde-b7f3-a3255ec4d9f9";
	// 		const s = d.getElementsByTagName("script")[0];
	// 		(s.parentNode as ParentNode).insertBefore(lc, s);
	// 		lc.onload = function () {
	// 			if (w.zE) {
	// 				if (typeof website === "string" && zendeskLocaleMap.hasOwnProperty(website)) {
	// 					zE("messenger:set", "locale", zendeskLocaleMap[website as keyof typeof zendeskLocaleMap]);
	// 				}
	// 				if (token.value) {
	// 					useRequest
	// 						.post("/api/zendesk/generateJwtToken")
	// 						.then(({ data }: { data: any }) => {
	// 							console.log("zendesk token", data.value.data.token);
	// 							if (data.value.data.token) {
	// 								const signedJwt = data.value.data.token;
	// 								zE("messenger", "loginUser", function (callback: (jwt: string) => void) {
	// 									callback(signedJwt);
	// 									console.log("loginUser with token", signedJwt);
	// 								});
	// 							}
	// 						})
	// 						.catch((err: any) => {
	// 							console.log(err);
	// 						});
	// 				}

	// 				// webSite field
	// 				console.log("zendesk get webSite:", website);
	// 				zE("messenger:set", "conversationFields", [
	// 					{ id: "35684241634073", value: website === "ru" ? "en" : website }
	// 				]);
	// 				// country field
	// 				console.log("zendesk get country_name: ", country_name);
	// 				zE("messenger:set", "conversationFields", [{ id: "28965480548121", value: country_name }]);
	// 				console.log("zendesk get countries_id:", countries_id);
	// 				zE("messenger:set", "conversationFields", [{ id: "36394089098009", value: countries_id || 0 }]);
	// 				zE("messenger:on", "unreadMessages", function (count: number) {
	// 					console.log(`You have ${count} unread message(s).`);
	// 				});
	// 				window.showFsLiveChat = function () {
	// 					zE("messenger", "open");
	// 				};
	// 				window.hideFsLiveChat = function () {
	// 					zE("messenger", "close");
	// 				};
	// 			}
	// 		};
	// 	})(window, document);
	// } else {
	// 	(function (w, d) {
	// 		const lc = d.createElement("script");
	// 		lc.type = "text/javascript";
	// 		lc.defer = true;
	// 		lc.src = lcSdkUrl;
	// 		const s = d.getElementsByTagName("script")[0];
	// 		(s.parentNode as ParentNode).insertBefore(lc, s);
	// 		lc.onload = function () {
	// 			if (w.fsLiveChatMount) {
	// 				w.fsLiveChatMount({
	// 					appId: 1, // 商城
	// 					isoCode: iso_code, // 地区isoCode
	// 					language: languageEnum[website as WebSiteType] || "", // 语言id
	// 					webSite: website, // 站点
	// 					clientUserInfo, // 登录用户信息
	// 					fromPage: window.location.href // 可选
	// 				});
	// 			}
	// 		};
	// 	})(window, document);
	// }
});
